import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills We Have</h5>
      <h2>Our Experience</h2>

      <div className="container experience__container">
          <div className="experience__content">
          <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>C#, VB.NET, Java, Python</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>.NET,  .NET Core</h4>
               </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>ASP.NET, MVC</h4>
               </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>Web API, WCF Services</h4>
               </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>Angular, React, Node.js, Vue.js</h4>
               </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>HTML, PHP, Laravel, Wordpress</h4>
               </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>SQL, MySql, MongoDB</h4>
               </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>JavaScript, Jquery, TypeScript</h4>
               </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
               <h4>Ionic, Flutter, ReactNative</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'  />
              <div>
               <h4>CSS, Bootstrap, Tailwind</h4>
              </div>
            </article>
        </div>
       
{/* 
        <div className="experience__backend">
        <h3>Backend Development</h3>
          <div className="experience__content">
           
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default Experience