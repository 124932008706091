import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiDribbble} from 'react-icons/fi'



const HeaderText = () => {
  return (
    <div className='header__text'>
        <p>Delivering high quality software services to our clients within scope and time.</p>
    </div>
  )
}

export default HeaderText