import React from 'react'
import './header.css'
import CTA from './CTA'
// import ME from '../../assets/me.png'
import MEVideo from '../../assets/videoheader.mp4'
import HeaderSocials from './HeaderSocials'
import HeaderLogo from './HeaderLogo'
import HeaderText from './HeaderText'

const Header = () => {
  return (
    <header>
      <div className="container header__container" style={{width:window.innerWidth}}>
        {/* <h5>Hello I'm</h5>
        <h1>Sokol Ajdini</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CTA /> */}
        <video autoPlay loop playsInline muted  src={MEVideo}  />
        <HeaderLogo/>
        <HeaderSocials />
        <HeaderText/>
        {/* <div className="me">
           <img src={ME} alt="me" /> 
          
        </div>  */}
        
        {/* <div className="mevideo">
          <video src={MEVideo} alt="me" autoPlay="true" />
        </div> */}

        {/* <a href="#contact" className='scroll__down'>Scroll Down</a> */}
      </div>
    </header>
  )
}

export default Header