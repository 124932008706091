import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'



const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About US</h2>
      <div className="container about__container">
        {/* <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div> */}

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className=''about__icon/>
              <h5>Experience</h5>
              <small>5+ Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className=''about__icon/>
              <h5>Clients</h5>
              <small>10+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className=''about__icon/>
              <h5>Projects</h5>
              <small>50+ Complited</small>
            </article>
          </div>

          <p>
UNICodes is a software development, engineering, and consulting company that offers comprehensive development services and consulting for a wide range of technologies. Our success is not a coincidence; it is the result of hard work, perseverance, continuous learning, and a genuine passion for what we do. We take pride in being a unique company that upholds its values and strives to fulfill its mission.
</p>
<p>
At UNICodes, we are dedicated to maintaining an independent perspective, bringing innovation to our clients, and fostering an unparalleled environment for exceptional individuals. We firmly believe in investing in our team's growth and development, which is why we actively participate in seminars, courses, and conferences to stay up-to-date with the latest advancements in the industry.
</p>
<p>
Thank you for considering UNICodes. We look forward to the opportunity to collaborate and provide you with exceptional solutions tailored to your needs.
</p>
          <div className="con"><a href="#contact" className='btn btn-primary'>Let's Talk</a></div>
          
        </div>
      </div>
    </section>
  )
}

export default About