import React from 'react'
import ME from '../../assets/logo.png'


const HeaderLogo = () => {
  return (
    <div className='header__logo'>
        <a href="#" target="_blank"><img src={ME} alt="logo" /></a>
    </div>
  )
}

export default HeaderLogo