import React from 'react'
import './portfolio.css'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/portfolio4.png'
import IMG5 from '../../assets/portfolio5.png'
// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'MAKAALB',
    github: '',
    demo: 'https://makalb.com/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'E DUA FSHATIN TIM',
    github: '',
    demo: 'https://eduafshatintim.com/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'AUTODIENST24',
    github: '',
    demo: 'https://autodienst24.at/'
  },
  {
    id: 4,
    image: IMG4,
    title: 'SECURITAS-M',
    github: '',
    demo: 'https://securitas-m.com/'
  },
  {
    id: 5,
    image: IMG5,
    title: 'VAOSC',
    github: '',
    demo: 'https://vaosc.netlify.app/'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>Our Recent Work</h5>
      <h2>Portfolio</h2>
      <Swiper className="container portfolio__container"
       // install Swiper modules
       modules={[ Pagination ]}
        spaceBetween={40}
        slidesPerView={3}
        breakpoints={{320: {
          slidesPerView: 1,
          spaceBetween: 40},
          601: {
          slidesPerView: 3,
          spaceBetween: 40
        }}}
       pagination={{ clickable: true }}>
        {
      // <div className="container portfolio__container">
      //  {
        data.map(({image, title, github, demo},id) => {
          return (
            <SwiperSlide  key={id} className='portfolio__item'>
            {/*  <article key={id} className='portfolio__item'> */}
             <div className="portfolio__item-image">
               <img src={image} alt={title} />
             </div>
             <h3>{title}</h3>
             <div className="portfolio__item-cta">
              <a href={github} className='btn' target='_blank'>Github</a>
              <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
             </div>
             </SwiperSlide>
          //  </article> 
          )
        })
       }
       </Swiper>
     {/* </div> */}
    </section>
  )
}

export default Portfolio