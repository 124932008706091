import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What We Offer</h5>
      <h2>Services</h2>
      
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>App Development</h3>
          </div>
          <div className="service__list"><p>At <strong>UNICodes</strong>, we specialize in full stack development for web, desktop, and web service applications. Our team creates highly robust, functional, and cost-effective enterprise applications tailored to meet your specific requirements. We ensure maximum return on investment by delivering exceptional solutions.</p><br/>
<p>Contact us today to discuss your app development needs. Let us turn your vision into a reality.</p></div>
          </article>
        {/* END OF App Development */}

        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <div className="service__list"><p>
    At <strong>UNICodes</strong>, we specialize in providing comprehensive web development solutions. Our team utilizes modern web technologies and best practices to create custom web applications that simplify complex business workflows. We integrate essential features like security, databases, web services, real-time communication, and shopping carts to enhance your business capabilities.
</p>
<br/>
<p>
    Our expert web developers prioritize creating visually appealing, highly functional, and user-friendly web applications. We focus on seamless user experiences and efficient navigation to optimize engagement and conversions.
</p>
<br/>
<p>
    Contact us today to discuss your web development needs and unlock your business's full potential with our powerful web application solutions.
</p></div>
          
        </article>
        {/* END OF WEB DEVELOPMENT */}

        <article className="service">
          <div className="service__head">
            <h3>Website</h3>
          </div>
          <div className="service__list"><p>
    With the latest technologies at our disposal, we have the capability to design a web presence that reflects your unique identity and meets the specific demands of the market. Our team specializes in creating visually stunning websites that set you apart from your competitors.
</p>
<br/>
<p>
    In addition to our design expertise, we offer comprehensive hosting and service solutions to ensure a secure and reliable website. From hosting services to regular maintenance, we have you covered.
</p>
<br/>
<p>
    We understand the importance of standing out in the digital landscape. That's why we focus on user experience, seamless navigation, and engaging visuals to drive conversions and achieve your business goals.
</p>
<br/>
<p>
    Contact us today to discuss your website requirements and let us create a compelling online presence for your brand.
</p></div>
          
        </article>
        {/* END OF Website */}

        <article className="service">
          <div className="service__head">
            <h3>Cross platform mobile app</h3>
          </div>
          <div className="service__list"><p>
    At <strong>UNICodes</strong>, we believe in the power of code efficiency and simultaneous multi-platform delivery. With our expertise in <em>Xamarin</em>, <em>Ionic</em>, and <em>React Native</em>, we can develop mobile applications that share a single codebase for business logic, allowing them to be deployed seamlessly on iOS, Android, and Windows Phone devices.
</p>
<br/>
<p>
    By leveraging the capabilities of these technologies, we enable our clients to reach a broader audience without the need for separate development efforts for each platform. This approach not only saves time and resources but also ensures consistent functionality and user experience across different mobile platforms.
</p>
<br/>
<p>
    Contact us today to discuss your cross-platform mobile app requirements and let us help you maximize your app's reach and impact. Together, we can create a powerful and versatile mobile solution for your business.
</p></div>
        </article>
        {/* END OF Cross platform mobile app */}
      </div>
    </section>
  )
}

export default Services